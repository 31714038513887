@tailwind base;
@tailwind components;
@tailwind utilities;

.react-tel-input .form-control {
    width: 100% !important;
}

.react-tel-input .flag-dropdown.open {
    z-index: 999;
}
@layer base {
    body {
        @apply font-satoshi font-normal text-base text-body bg-whiten relative z-1;
    }
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@layer components {}

@layer utilities {

    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .chat-height {
        @apply h-[calc(100vh_-_8.125rem)] lg:h-[calc(100vh_-_5.625rem)];
    }

    .inbox-height {
        @apply h-[calc(100vh_-_8.125rem)] lg:h-[calc(100vh_-_5.625rem)];
    }
}

/* third-party libraries CSS */

.tableCheckbox:checked~div span {
    @apply opacity-100;
}

.tableCheckbox:checked~div {
    @apply bg-primary border-primary;
}

.apexcharts-legend-text {
    @apply !text-body dark:!text-bodydark;
}

.apexcharts-text {
    @apply !fill-body dark:!fill-bodydark;
}

.apexcharts-xcrosshairs {
    @apply !fill-stroke dark:!fill-strokedark;
}

.apexcharts-gridline {
    @apply !stroke-stroke dark:!stroke-strokedark;
}

.apexcharts-series.apexcharts-pie-series path {
    @apply dark:!stroke-transparent;
}

.apexcharts-legend-series {
    @apply !inline-flex gap-1.5;
}

.apexcharts-tooltip.apexcharts-theme-light {
    @apply dark:!bg-boxdark dark:!border-strokedark;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    @apply dark:!bg-meta-4 dark:!border-strokedark;
}

.apexcharts-xaxistooltip,
.apexcharts-yaxistooltip {
    @apply dark:!bg-meta-4 dark:!border-meta-4 dark:!text-bodydark1;
}

.apexcharts-xaxistooltip-bottom:after {
    @apply dark:!border-b-meta-4;
}

.apexcharts-xaxistooltip-bottom:before {
    @apply dark:!border-b-meta-4;

}

.flatpickr-day.selected {
    @apply bg-primary border-primary hover:bg-primary hover:border-primary;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
    @apply fill-primary;
}

.flatpickr-calendar.arrowTop:before {
    @apply dark:!border-b-boxdark;
}

.flatpickr-calendar.arrowTop:after {
    @apply dark:!border-b-boxdark;
}

.flatpickr-calendar {
    @apply dark:!bg-boxdark dark:!text-bodydark dark:!shadow-8 !p-6 2xsm:!w-auto;
}

.flatpickr-day {
    @apply dark:!text-bodydark;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
    @apply !top-7 dark:!text-white dark:!fill-white;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
    @apply !left-7
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
    @apply !right-7
}

span.flatpickr-weekday,
.flatpickr-months .flatpickr-month {
    @apply dark:!text-white dark:!fill-white;
}

.flatpickr-day.inRange {
    @apply dark:!bg-meta-4 dark:!border-meta-4 dark:!shadow-7;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.selected,
.flatpickr-day.endRange {
    @apply dark:!text-white;
}

.map-btn .jvm-zoom-btn {
    @apply flex items-center justify-center w-7.5 h-7.5 rounded border border-stroke dark:border-strokedark hover:border-primary dark:hover:border-primary bg-white hover:bg-primary text-body hover:text-white dark:text-bodydark dark:hover:text-white text-2xl leading-none px-0 pt-0 pb-0.5;
}

.mapOne .jvm-zoom-btn {
    @apply left-auto top-auto bottom-0;
}

.mapOne .jvm-zoom-btn.jvm-zoomin {
    @apply right-10;
}

.mapOne .jvm-zoom-btn.jvm-zoomout {
    @apply right-0;
}

.mapTwo .jvm-zoom-btn {
    @apply top-auto bottom-0;
}

.mapTwo .jvm-zoom-btn.jvm-zoomin {
    @apply left-0;
}

.mapTwo .jvm-zoom-btn.jvm-zoomout {
    @apply left-10;
}

.taskCheckbox:checked~.box span {
    @apply opacity-100;
}

.taskCheckbox:checked~p {
    @apply line-through;
}

.taskCheckbox:checked~.box {
    @apply bg-primary border-primary dark:border-primary;
}

.custom-input-date::-webkit-calendar-picker-indicator {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
}

.custom-input-date-1::-webkit-calendar-picker-indicator {
    background-image: url(./images/icon/icon-calendar.svg);
}

.custom-input-date-2::-webkit-calendar-picker-indicator {
    background-image: url(./images/icon/icon-arrow-down.svg);
}

[x-cloak] {
    display: none !important;
}